import React from 'react'
import { Link } from 'react-router-dom'

const E404 = () => {
  return (
    <div>E404
        <Link to='/' >Homepage</Link>
    </div>
  )
}

export default E404